<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 21 20"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 1H3.5L3.9 3M5.5 11H15.5L19.5 3H3.9M5.5 11L3.9 3M5.5 11L3.20711 13.2929C2.57714 13.9229 3.02331 15 3.91421 15H15.5M15.5 15C14.3954 15 13.5 15.8954 13.5 17C13.5 18.1046 14.3954 19 15.5 19C16.6046 19 17.5 18.1046 17.5 17C17.5 15.8954 16.6046 15 15.5 15ZM7.5 17C7.5 18.1046 6.60457 19 5.5 19C4.39543 19 3.5 18.1046 3.5 17C3.5 15.8954 4.39543 15 5.5 15C6.60457 15 7.5 15.8954 7.5 17Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>
